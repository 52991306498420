<script>
import CustomersService from '@/service/customers.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import cloneDeep from 'lodash/cloneDeep';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-register-customer',

  components: {
    FormAvatarUpload: () => import('@/components/FormAvatarUpload'),
  },

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: {},
    };
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    create() {
      this.form = {};
      this.dialog = true;
    },

    edit(id) {
      this.fetch(id);
      this.dialog = true;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const customer = await CustomersService.get(id);
        this.form = cloneDeep(customer);
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        form.avatarId = this.$refs?.avatar?.avatarId;
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await CustomersService.update(id, form);
        } else {
          await CustomersService.create(form);
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de usuário</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="12" sm="5">
                  <v-text-field
                    v-model="form.id"
                    label="ID"
                    color="white"
                    hide-details="auto"
                    disabled
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.cpf"
                    v-mask="'###.###.###-##'"
                    label="CPF"
                    color="white"
                    hide-details="auto"
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Nome"
                    color="white"
                    hide-details="auto"
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.gender"
                    :items="[
                      { text: 'Masculino', value: 'M' },
                      { text: 'Feminino', value: 'F' },
                    ]"
                    label="Gênero"
                    color="white"
                    hide-details="auto"
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.access"
                    :items="[
                      { text: 'Permitir', value: true },
                      { text: 'Bloquear', value: false },
                    ]"
                    label="Acesso"
                    color="white"
                    hide-details="auto"
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.startTime"
                    label="Hora início"
                    type="time"
                    color="white"
                    hide-details="auto"
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.endTime"
                    label="Hora fim"
                    type="time"
                    color="white"
                    hide-details="auto"
                    outlined
                    persistent-placeholder
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="form.freeAccess"
                    color="white"
                    label="Este usuário possui acesso livre"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="text-center">
                <FormAvatarUpload v-if="dialog" ref="avatar" :value="form.avatar" tenant />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
