var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(!_vm.form.id ? 'Cadastro' : 'Edição')+" de usuário")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"label":"ID","color":"white","hide-details":"auto","disabled":"","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"label":"CPF","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":[
                    { text: 'Masculino', value: 'M' },
                    { text: 'Feminino', value: 'F' },
                  ],"label":"Gênero","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":[
                    { text: 'Permitir', value: true },
                    { text: 'Bloquear', value: false },
                  ],"label":"Acesso","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.access),callback:function ($$v) {_vm.$set(_vm.form, "access", $$v)},expression:"form.access"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Hora início","type":"time","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Hora fim","type":"time","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"color":"white","label":"Este usuário possui acesso livre","hide-details":"auto"},model:{value:(_vm.form.freeAccess),callback:function ($$v) {_vm.$set(_vm.form, "freeAccess", $$v)},expression:"form.freeAccess"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-center"},[(_vm.dialog)?_c('FormAvatarUpload',{ref:"avatar",attrs:{"value":_vm.form.avatar,"tenant":""}}):_vm._e()],1)])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loadingSave},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }